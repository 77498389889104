<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <card class='pb-5'>
          <div class="d-flex justify-content-between align-items-center px-4 pt-4">
            <h3 class="card-title font-weight-bold" style='font-size: 30px'>Clientes</h3>
            <article class="d-flex justify-content-between align-items-center">
              <vs-button @click="$router.push('NuevoCliente')">Nuevo Cliente</vs-button>
            </article>
          </div>
          <div class="px-5 py-3">
            <CardTable :data="dataCards" />
          </div>
          <vs-table v-model="selected" class='px-4 py-3'>
            <template #header>
              <vs-input v-model="search" border placeholder="Buscar un Artículo" />
            </template>
            <template #thead>
              <vs-tr>
                <vs-th>
                  <vs-checkbox :indeterminate="selected.length == customers.length" v-model="allCheck"
                    @change="selected = $vs.checkAll(selected, customers)" />
                </vs-th>
                <vs-th sort @click="customers = $vs.sortData($event, customers, 'Nombre')">
                  Nombre
                </vs-th>
                <vs-th sort style='max-width: 200px;'
                  @click="customers = $vs.sortData($event, customers, 'CIFNIF')">
                  CIF/NIF
                </vs-th>
                <vs-th sort @click="customers = $vs.sortData($event, customers, 'CorreoElectronico')">
                  Correo Electrónico
                </vs-th>
                <vs-th style='width: 150px;' sort @click="customers = $vs.sortData($event, customers, 'Telefono')">
                  Teléfono
                </vs-th>
                <vs-th sort @click="customers = $vs.sortData($event, customers, 'Pais')">
                  País
                </vs-th>
                <vs-th sort @click="customers = $vs.sortData($event, customers, 'Pendiente')">
                  Pendiente
                </vs-th>
                <vs-th sort @click="customers = $vs.sortData($event, customers, 'Pagado')">
                  Cancelado
                </vs-th>
                <vs-th>
                  Opciones
                </vs-th>
              </vs-tr>
            </template>
            <template #tbody style='background: var(--vs-background) !important; margin-top: 20px;'>
              <vs-tr :key="i" v-for="(tr, i) in $vs.getPage($vs.getSearch(customers, search), page, max)" :data="tr"
                :is-selected="!!selected.includes(tr)" not-click-selected open-expand-only-td>
                <vs-td checkbox>
                  <vs-checkbox :val="tr" v-model="selected" />
                </vs-td>
                <vs-td>
                  {{ tr.Nombre }}
                </vs-td>
                <vs-td>
                  {{ tr.CIFNIF }}
                </vs-td>
                <vs-td>
                  {{ tr.CorreoElectronico }}
                </vs-td>
                <vs-td>
                  {{ tr.Telefono }}
                </vs-td>
                <vs-td>
                  {{ tr.Pais }}
                </vs-td>
                <vs-td>
                  {{ tr.Pendiente }}
                </vs-td>
                <vs-td>
                  {{ tr.Pagado }}
                </vs-td>
                <vs-td style="width: 150px !important">
                  <div class='d-flex'>
                    <vs-button><ion-icon name="create-outline"></ion-icon></vs-button>
                    <vs-button class='bg-danger'><ion-icon name="trash-outline"></ion-icon></vs-button>
                  </div>
                </vs-td>
              </vs-tr>
            </template>
            <template #footer>
              <vs-pagination v-model="page" :length="$vs.getLength($vs.getSearch(customers, search), max)" />
            </template>
          </vs-table>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import CardTable from "../../../../../../components/cardTable/CardTableV2.vue";
  export default {
    components: {
      CardTable,
    },
    data() {
      return {
        editActive: false,
        edit: null,
        editProp: {},
        search: '',
        allCheck: false,
        page: 1,
        max: 5,
        active: 0,
        selected: [],
        dataCards: [
          {
            title: "Pendiente",
            value: 0,
            iconName: "money-bill-1",
            color: "rgba(255, 0, 0, 1)",
            tipo: "shield",
            animated: "bounce",
          },
          {
            title: "Cancelado",
            value: 0,
            iconName: "money-bill-1",
            color: "rgba(71,136,255,1)",
            tipo: "shield",
            animated: "bounce",
          }
        ],
        customers: [
          {
            "id": "1",
            "Nombre": "Juan Pérez",
            "CIFNIF": "A12345678",
            "CorreoElectronico": "juan.perez@example.com",
            "Telefono": "+34 123 456 789",
            "Pais": "España",
            "Pendiente": 500.00,
            "Pagado": 1500.00
          },
          {
            "id": "2",
            "Nombre": "María Gómez",
            "CIFNIF": "B98765432",
            "CorreoElectronico": "maria.gomez@example.com",
            "Telefono": "+34 987 654 321",
            "Pais": "España",
            "Pendiente": 700.00,
            "Pagado": 1000.00
          },
          {
            "id": "3",
            "Nombre": "Carlos Fernández",
            "CIFNIF": "C54321678",
            "CorreoElectronico": "carlos.fernandez@example.com",
            "Telefono": "+34 654 321 098",
            "Pais": "España",
            "Pendiente": 0.00,
            "Pagado": 2500.00
          },
          {
            "id": "4",
            "Nombre": "Laura Martín",
            "CIFNIF": "D87654321",
            "CorreoElectronico": "laura.martin@example.com",
            "Telefono": "+34 321 654 987",
            "Pais": "España",
            "Pendiente": 1000.00,
            "Pagado": 0.00
          },
          {
            "id": "5",
            "Nombre": "Miguel Torres",
            "CIFNIF": "E34567890",
            "CorreoElectronico": "miguel.torres@example.com",
            "Telefono": "+34 765 432 109",
            "Pais": "España",
            "Pendiente": 250.00,
            "Pagado": 750.00
          },
          {
            "id": "6",
            "Nombre": "Ana López",
            "CIFNIF": "F09876543",
            "CorreoElectronico": "ana.lopez@example.com",
            "Telefono": "+34 876 543 210",
            "Pais": "España",
            "Pendiente": 300.00,
            "Pagado": 1200.00
          },
          {
            "id": "7",
            "Nombre": "David Sánchez",
            "CIFNIF": "G12345679",
            "CorreoElectronico": "david.sanchez@example.com",
            "Telefono": "+34 432 109 876",
            "Pais": "España",
            "Pendiente": 450.00,
            "Pagado": 850.00
          },
          {
            "id": "8",
            "Nombre": "Isabel Ruiz",
            "CIFNIF": "H98765430",
            "CorreoElectronico": "isabel.ruiz@example.com",
            "Telefono": "+34 567 890 123",
            "Pais": "España",
            "Pendiente": 600.00,
            "Pagado": 400.00
          }
        ]
      }
    },
    created() {
      this.updateDataCardsValues();
    },
    methods: {
      updateDataCardsValues() {
        const pendienteTotal = this.customers.reduce((sum, customer) => sum + customer.Pendiente, 0);
        const pagadoTotal = this.customers.reduce((sum, customer) => sum + customer.Pagado, 0);
        this.dataCards[0].value = pendienteTotal;
        this.dataCards[1].value = pagadoTotal;
      }
    }
  }
</script>

<style>
  .vs-table__header
  .vs-input
  .vs-table__footer
  .vs-pagination__arrow
  .vs-pagination__arrow {
    background: var(--body-bg) !important;
  }
</style>